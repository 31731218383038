<template>
  <div>
     <el-row>
       <el-col style="padding-bottom:20px">
          <el-button type="primary" @click="intelligentDoorLockBtn('开锁')"  v-loading.fullscreen.lock="fullscreenLoading">远程开锁</el-button>
          <el-button type="primary" @click="intelligentDoorLockBtn('时间同步')" v-loading.fullscreen.lock="fullscreenLoading">时间同步</el-button>
          <el-button type="primary" @click="intelligentDoorLockBtn('密码启用')" v-loading.fullscreen.lock="fullscreenLoading">密码启用</el-button>
          <el-button type="primary" @click="intelligentDoorLockBtn('密码禁用')" v-loading.fullscreen.lock="fullscreenLoading">密码禁用</el-button>
          <el-button type="primary" @click="passwordRegistration(1)">注册密码</el-button>
          <el-button type="primary" @click="passwordRegistration(2)">注销密码</el-button>
       </el-col>
       <el-col>
         <el-button type="primary" @click="intelligentDoorLockBtn('指纹启用')" v-loading.fullscreen.lock="fullscreenLoading">指纹启用</el-button>
         <el-button type="primary" @click="intelligentDoorLockBtn('指纹禁用')" v-loading.fullscreen.lock="fullscreenLoading">指纹禁用</el-button>
         <el-button type="primary" @click="intelligentDoorLockBtn('IC卡启用')" v-loading.fullscreen.lock="fullscreenLoading">IC卡启用</el-button>
         <el-button type="primary" @click="intelligentDoorLockBtn('IC卡禁用')" v-loading.fullscreen.lock="fullscreenLoading">IC卡禁用</el-button>
         <el-button type="primary" @click="ICRegistration(1)">注册IC卡</el-button>
         <el-button type="primary" @click="ICRegistration(2)">注销IC卡</el-button>
       </el-col>
     </el-row>
     <el-dialog
       title=""
       :visible.sync="pwdRegisterVisible"
       width="width"
       append-to-body
       @close="pswClosedialog"
       >
       <el-form :inline="true" ref="password" :model="password" :rules="rulesPassword">
         <div v-show="this.pswICShow==2">
           <el-row>
             <el-col :span="7">
              <el-form-item label="用 户">
                <el-cascader
                  :options="userNameList"
                  @change="selectICUser"
                  v-model="password.user2"
                  :props="{ expandTrigger: 'hover' }"
                  :show-all-levels="false"
                  size="mini"
                  class="screenInput"
                  clearable
                >
                </el-cascader>
             </el-form-item>
             </el-col>
           </el-row>
         </div>
         <div v-show="this.pswICShow==1">
            <el-row>
              <el-col :span="7">
                <el-form-item label="用 户" prop="user">
                <el-cascader
                  :options="userNameList"
                  @change="selectICUser"
                  v-model="password.user"
                  :props="{ expandTrigger: 'hover' }"
                  :show-all-levels="false"
                  size="mini"
                  class="screenInput"
                  clearable
                >
                </el-cascader>
              </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="密 码" prop="psw">
                <el-input size="mini" v-model="password.psw" placeholder="请输入" class="screenInput"></el-input>
              </el-form-item> 
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="有效时间" prop="startDate">
                  <el-date-picker 
                    v-model="password.startDate" 
                    type="date" 
                    placeholder="选择日期" 
                    value-format="yyyy-MM-dd" 
                    size="mini" 
                    style="width:125px" 
                    :picker-options="dateLimit">
                  </el-date-picker>
              </el-form-item>
              <el-form-item prop="startHours">
                <el-select 
                  v-model="password.startHours" 
                  placeholder="选择小时" 
                  size="mini" 
                  style="width: 100px;" 
                  class="none-icon"
                >
                  <el-option 
                      v-for="item in optionsHours" 
                      :key="item.value" 
                      :label="item.label" 
                      :disabled="item.disabled" 
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>:</el-form-item>
              <el-form-item prop="startMinutes">
                    <el-select 
                        v-model="password.startMinutes" 
                        placeholder="选择分钟" 
                        size="mini" 
                        style="width: 100px;" 
                        class="none-icon"
                        
                    >
                        <el-option 
                            v-for="item in optionsMinutes" 
                            :key="item.value" 
                            :label="item.label" 
                            :disabled="item.disabled" 
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
              </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="失效时间" prop="endDate">
                <el-date-picker 
                  v-model="password.endDate" 
                  type="date"  
                  placeholder="选择日期" 
                  value-format="yyyy-MM-dd" 
                  size="mini" 
                  style="width:125px" 
                  :picker-options="dateLimit">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="endHours">
              <el-select 
                v-model="password.endHours" 
                placeholder="选择小时" 
                size="mini" 
                style="width: 100px;" 
                class="none-icon"
            >
              <el-option 
                  v-for="item in optionsHours" 
                  :key="item.value" 
                  :label="item.label" 
                  :disabled="item.disabled" 
                  :value="item.value"
              >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>:</el-form-item>
            <el-form-item prop="endMinutes">
                  <el-select 
                      v-model="password.endMinutes" 
                      placeholder="选择分钟" 
                      size="mini" 
                      style="width: 100px;" 
                      class="none-icon"
                  >
                  <el-option 
                      v-for="item in optionsMinutes" 
                      :key="item.value" 
                      :label="item.label" 
                      :disabled="item.disabled" 
                      :value="item.value"
                  >
                      </el-option>
                  </el-select>
            </el-form-item>
              </el-col>
            </el-row>
         </div>
       </el-form>
       <div slot="footer">
         <el-button @click="pwdRegisterVisible = false">取 消</el-button>
         <el-button type="primary" @click="passwordICFunction">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog
       title=""
       :visible.sync="ICCardVisible"
       width="width"
       append-to-body
       @close="IcClosedialog"
      >
        <el-form :inline="true" ref="ICCard" :model="ICCard" :rules="rulesICCard">
          <div v-show="this.IcShow==1">
           <el-row>
              <el-col :span="7">
                <el-form-item label="用 户" prop="user">
                  <el-cascader
                    :options="userNameList"
                    @change="selectICUser"
                    v-model="ICCard.user"
                    :props="{ expandTrigger: 'hover' }"
                    :show-all-levels="false"
                    size="mini"
                    class="screenInput"
                    clearable
                  >
                  </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="cardId">
                  授权卡
                  <el-input v-model="ICCard.cardId" size="mini" class="screenInput"></el-input>
                  <el-button
                      icon="el-icon-bank-card"
                      size="mini"
                      @click="childMethod()"
                      style="margin-left:10px">读卡
                  </el-button>
                </el-form-item>
              </el-col>
           </el-row>
           <el-row>
              <el-form-item label="有效时间" prop="startDate">
                  <el-date-picker 
                    v-model="ICCard.startDate" 
                    type="date"  
                    placeholder="选择日期" 
                    value-format="yyyy-MM-dd" 
                    size="mini" 
                    style="width:125px" 
                    :picker-options="dateLimit">
                  </el-date-picker>
              </el-form-item>
              <el-form-item prop="startHours">
                <el-select 
                  v-model="ICCard.startHours" 
                  placeholder="选择小时" 
                  size="mini" 
                  style="width: 100px;" 
                  class="none-icon"
                >
                  <el-option 
                    v-for="item in optionsHours" 
                    :key="item.value" 
                    :label="item.label" 
                    :disabled="item.disabled" 
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>:</el-form-item>
              <el-form-item prop="startMinutes">
                <el-select 
                    v-model="ICCard.startMinutes" 
                    placeholder="选择分钟" 
                    size="mini" 
                    style="width: 100px;" 
                    class="none-icon"
                >
                <el-option 
                    v-for="item in optionsMinutes" 
                    :key="item.value" 
                    :label="item.label" 
                    :disabled="item.disabled" 
                    :value="item.value"
                >
                </el-option>
                </el-select>
              </el-form-item>
           </el-row>
           <el-row>
           <el-col>
              <el-form-item label="失效时间" prop="endDate">
              <el-date-picker 
                v-model="ICCard.endDate" 
                type="date"  
                placeholder="选择日期" 
                value-format="yyyy-MM-dd" 
                size="mini" 
                style="width:125px" 
                :picker-options="dateLimit">
              </el-date-picker>
          </el-form-item>
          <el-form-item prop="endHours">
            <el-select 
              v-model="ICCard.endHours" 
              placeholder="选择小时" 
              size="mini" 
              style="width: 100px;" 
              class="none-icon"
          >
            <el-option 
                v-for="item in optionsHours" 
                :key="item.value" 
                :label="item.label" 
                :disabled="item.disabled" 
                :value="item.value"
            >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>:</el-form-item>
          <el-form-item prop="endMinutes">
            <el-select 
                v-model="ICCard.endMinutes" 
                placeholder="选择分钟" 
                size="mini" 
                style="width: 100px;" 
                class="none-icon"
            >
            <el-option 
                v-for="item in optionsMinutes" 
                :key="item.value" 
                :label="item.label" 
                :disabled="item.disabled" 
                :value="item.value"
            >
            </el-option>
            </el-select>
          </el-form-item>
           </el-col>
           </el-row>
          </div>
          <div v-show="this.IcShow==2">
            <el-form-item label="用 户">
              <el-cascader
                :options="userNameList"
                @change="selectICUser"
                v-model="ICCard.user2"
                :props="{ expandTrigger: 'hover' }"
                :show-all-levels="false"
                size="mini"
                class="screenInput"
                clearable
              >
              </el-cascader>
            </el-form-item>
          </div>
        </el-form>
       <div slot="footer">
         <el-button @click="ICCardVisible = false">取 消</el-button>
         <el-button type="primary" @click="ICCardBtn">确 定</el-button>
       </div>
     </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    getCard: {
      type: Function,
      default: null
    },
     row: {
      type: Object | String,
    },
  },
  data(){
    return{
      doorLock:{},
      pwdRegisterVisible:false,
      ICCardVisible:false,
      fullscreenLoading:false,
      dateLimit: { //日期限制
        disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
        },
      },
      optionsHours: [
        {
            value: '00',
            label: '00',
            disabled: false
        }, {
            value: '01',
            label: '01',
            disabled: false
        },
        {
            value: '02',
            label: '02',
            disabled: false
        },
        {
            value: '03',
            label: '03',
            disabled: false
        },
        {
            value: '04',
            label: '04',
            disabled: false
        },
        {
            value: '05',
            label: '05',
            disabled: false
        },
        {
            value: '06',
            label: '06',
            disabled: false
        },
        {
            value: '07',
            label: '07',
            disabled: false
        },
        {
            value: '08',
            label: '08',
            disabled: false
        },
        {
            value: '09',
            label: '09',
            disabled: false
        },
        {
            value: '10',
            label: '10',
            disabled: false
        },
        {
            value: '11',
            label: '11',
            disabled: false
        },
        {
            value: '12',
            label: '12',
            disabled: false
        },
        {
            value: '13',
            label: '13',
            disabled: false
        },
        {
            value: '14',
            label: '14',
            disabled: false
        },
        {
            value: '15',
            label: '15',
            disabled: false
        },
        {
            value: '16',
            label: '16',
            disabled: false
        },
        {
            value: '17',
            label: '17',
            disabled: false
        },
        {
            value: '18',
            label: '18',
            disabled: false
        },
        {
            value: '19',
            label: '19',
            disabled: false
        },
        {
            value: '20',
            label: '20',
            disabled: false
        },
        {
            value: '21',
            label: '21',
            disabled: false
        },
        {
            value: '22',
            label: '22',
            disabled: false
        },
        {
            value: '23',
            label: '23',
            disabled: false
        },
      ],
      optionsMinutes: [
        {
            value: '00',
            label: '00',
            disabled: false
        },
        {
            value: '20',
            label: '20',
            disabled: false
        },
        {
            value: '40',
            label: '40',
            disabled: false
        },
      ],
      password:{
        jdcUserId:"",
        psw:"",
        user:"",
        user2:"",
        startDate:"",
        startHours:"",
        startMinutes:"",
        endDate:"",
		    endHours:"",
		    endMinutes:"",
      },
      ICCard:{
        user:"",
        user2:"",
        cardId:"",
        jdcUserId:"",
        startDate:"",
        startHours:"",
        startMinutes:"",
        endDate:"",
		    endHours:"",
		    endMinutes:"",
      },
      rulesPassword:{
        user:[{
            required: true,
            message: '用户不能为空',
            trigger: 'change',
        }],
        psw: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur',
        },
        {
          min: 6,
          max: 6,
          message: '密码长度须为6个字符',
          trigger: 'blur',
      }],
      startDate:[{
          required: true,
          message: '日期不能为空',
          trigger: 'change',
      }],
      startHours:[{
          required: true,
          message: '时间不能为空',
          trigger: 'change',
      }],
      startMinutes:[{
          required: true,
          message: '分钟不能为空',
          trigger: 'change',
      }],
      endDate:[{
          required: true,
          message: '日期不能为空',
          trigger: 'change',
      }],
      endHours:[{
          required: true,
          message: '时间不能为空',
          trigger: 'change',
      }],
      endMinutes:[{
          required: true,
          message: '分钟不能为空',
          trigger: 'change',
      }],
      },
      rulesICCard:{
          user:[{
             required: true,
             message: '用户不能为空',
             trigger: 'change',
          }],
          cardId:[{
            required: true,
            message: '授权卡不能为空',
            trigger: 'blur',
         },
        ],
        startDate:[{
            required: true,
            message: '日期不能为空',
            trigger: 'change',
        }],
        startHours:[{
            required: true,
            message: '时间不能为空',
            trigger: 'change',
        }],
        startMinutes:[{
            required: true,
            message: '分钟不能为空',
            trigger: 'change',
        }],
        endDate:[{
            required: true,
            message: '日期不能为空',
            trigger: 'change',
        }],
        endHours:[{
            required: true,
            message: '时间不能为空',
            trigger: 'change',
        }],
        endMinutes:[{
            required: true,
            message: '分钟不能为空',
            trigger: 'change',
        }],
      },
      pswICShow:"",
      IcShow:"",
    }
  },
  methods:{
    intelligentDoorLockBtn(val){
      //  console.log(val)
       this.fullscreenLoading=true
       this.doorLock.devState=val
       // this.doorLock.devId=1901 
       this.doorLock.devId =this.row.jourDeviceId
       this.intelligentDoorLock(this.doorLock)
    },
    passwordRegistration(val){
      this.pswICShow=val
      this.pwdRegisterVisible=true
    },
    ICRegistration(val){
      this.IcShow=val
      this.ICCardVisible=true
    },
    intelligentDoorLock(data){
      this.$axios({
        url:"/common2/devices/operate",
        method:"POST",
        data
      }).then(res=>{
        if(res.data.status){
           this.$message.success('控制成功')
           this.fullscreenLoading=false
        }else{
          this.$message.error('控制失败')
          this.fullscreenLoading=false
        }
      }).catch(err=>{
         this.isError(err, this)
         this.fullscreenLoading=false
      })
    },
    selectICUser(arr) {
      this.password.jdcUserId =arr!=''?arr[2]:''
      this.ICCard.jdcUserId=arr!=''?arr[2]:''
    },
    passwordICFunction(){
      if(this.pswICShow==2){
        if(this.password.user2==''||this.password.user2==undefined){
          this.$message.warning('用户不能为空')
           return;
         }
          delete this.doorLock.startTime;
          delete this.doorLock.jdcDeadlineTime;
          delete this.doorLock.authorizeType;
          delete this.doorLock.isAppAuthorization;
          delete this.doorLock.sendMessage;
          this.doorLock.devId=this.row.jourDeviceId
          this.doorLock.devState="注销用户密码"
          this.doorLock.jdcUserId= this.password.jdcUserId
          this.intelligentDoorLock(this.doorLock)
          this.pwdRegisterVisible=false
      }else{
         if(this.submitForm(['password'],this)){
            let startTime = `${this.password.startDate} ${this.password.startHours}${this.password.startHours!=''?':':''}${this.password.startMinutes}${this.password.startMinutes!=''?':00':''}`
            let jdcDeadlineTime = `${this.password.endDate} ${this.password.endHours}${this.password.endHours!=''?':':''}${this.password.endMinutes}${this.password.endMinutes!=''?':00':''}`
            // console.log(startTime,jdcDeadlineTime)
            if(startTime==jdcDeadlineTime){
              this.$message.warning('有效时间与失效时间相同！')
              return;
            }
            this.doorLock.devId=this.row.jourDeviceId
            this.doorLock.devState="添加密码"
            this.doorLock.jdcUserId= this.password.jdcUserId
            this.doorLock.jdcPassword=this.password.psw,
            this.doorLock.startTime=startTime,
            this.doorLock.jdcDeadlineTime=jdcDeadlineTime,
            this.doorLock.authorizeType=1,
            this.doorLock.isAppAuthorization=false,
            this.doorLock.sendMessage=1
            this.intelligentDoorLock(this.doorLock)
            this.pwdRegisterVisible=false
        }
      }
    },
    ICCardBtn(){
      if(this.IcShow==1){
      let startTime = `${this.ICCard.startDate} ${this.ICCard.startHours}${this.ICCard.startHours!=''?':':''}${this.ICCard.startMinutes}${this.ICCard.startMinutes!=''?':00':''}`
      let jdcDeadlineTime = `${this.ICCard.endDate} ${this.ICCard.endHours}${this.ICCard.endHours!=''?':':''}${this.ICCard.endMinutes}${this.ICCard.endMinutes!=''?':00':''}`
      if(this.submitForm(['ICCard'],this)){
         if(startTime==jdcDeadlineTime){
            this.$message.warning('有效时间与失效时间相同！')
            return;
         }
        this.doorLock.devId=this.row.jourDeviceId
        this.doorLock.devState="注册IC卡"
        this.doorLock.jdcUserId= this.ICCard.jdcUserId
        this.doorLock.jdcCardId= this.ICCard.cardId
        this.doorLock.startTime=startTime
        this.doorLock.jdcDeadlineTime=jdcDeadlineTime
        this.doorLock.jdcDeadlineTime=jdcDeadlineTime
        this.doorLock.authorizeType=0
        this.doorLock.isAppAuthorization=false
        this.doorLock.sendMessage=1
        this.intelligentDoorLock(this.doorLock)
        this.ICCardVisible=false
      }
      }else{
        if(this.ICCard.user2==''||this.ICCard.user2==undefined){
           this.$message.warning('用户不能为空')
           return;
         }
        delete this.doorLock.startTime;
        delete this.doorLock.jdcDeadlineTime;
        delete this.doorLock.authorizeType;
        delete this.doorLock.isAppAuthorization;
        delete this.doorLock.sendMessage;
        delete this.doorLock.jdcCardId
        this.doorLock.devId=this.row.jourDeviceId
        this.doorLock.devState="注销用户IC卡"
        this.doorLock.jdcUserId= this.ICCard.jdcUserId
        this.intelligentDoorLock(this.doorLock)
        this.ICCardVisible=false
      }
    },
    childMethod(){
      this.getCard()
    },
    pswClosedialog(){
      this.password.jdcUserId="",
      this.password.psw="",
      this.password.user="",
      this.password.user2="",
      this.password.startDate="",
      this.password.startHours="",
      this.password.startMinutes="",
      this.password.endDate="",
      this.password.endHours="",
      this.password.endMinutes=""
    },
    IcClosedialog(){
      this.ICCard.user="",
      this.ICCard.user2="",
      this.ICCard.cardId="",
      this.ICCard.jdcUserId="",
      this.ICCard.startDate="",
      this.ICCard.startHours="",
      this.ICCard.startMinutes="",
      this.ICCard.endDate="",
      this.ICCard.endHours="",
      this.ICCard.endMinutes=""
    }
  },
  computed: {
      ...mapState(['userNameList']),
    },
}
</script>
<style scoped lang='scss'>
.screenInput {
  width: 125px;
}
</style>